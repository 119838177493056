<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Role Permission</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('rolepermissioncustomer_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Access Permission
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                <v-btn-toggle class="col-12 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.TypeID}}</td>
                            <td>{{props.item.TypeName}}</td>
                            <td>{{props.item.RoleID}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('rolepermissioncustomer_update')">
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="goToPermission(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.RoleName}}</a>
                                    </template>
                                    <span>Update Access Permission</span>
                                </v-tooltip>
                                <span v-else>{{props.item.RoleName}}</span>
                            </td>
                            <td>{{props.item.displayStatus}}</td>
                            <!--
                            <td>{{props.item.createdDate}}</td>
                            <td>{{props.item.updatedDate}}</td>
                            -->
                            <!--
                            <td>
                                <v-tooltip top v-if="validateAccess('admin_update_permission')">
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="goToPermission(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.TypeRole}}</a>
                                    </template>
                                    <span>Update Access Permission</span>
                                </v-tooltip>
                                <span v-else>{{props.item.TypeRole}}</span>
                            </td>
                            -->
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :profilerole="profilerole" :profiletype="profiletype" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateAccessPerm';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Role Permission',
    title: 'Role Permission',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'SP Console Setting',
                disabled: true,
                href: '#',
                },

                {
                text: 'User Management',
                disabled: true,
                href: '#',
                },

                {
                text: 'Role Permission',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Profile Type ID', value: 'TypeID', width: '3%' },
                { text: 'Profile Type', value: 'TypeName', width: '15%' },
                { text: 'Role ID', value: 'RoleID', width: '3%' },
                { text: 'Role', value: 'RoleName', width: '15%' },
                { text: 'Display Status', value: 'displayStatus', width: '15%' },
                //{ text: 'Created Date', value: 'createdDate', width: '10%' },
                //{ text: 'Updated Date', value: 'updateDate', width: '10%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            services: [],
            profilerole: [],
            profiletype: []
        }
    },

    components: {create, alert, alertbox2},

    async created(){
        await this.getProfileType();
        this.getProfileRole();
    },

    methods: {
        async getProfileRole(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("listProfileRoleCustomer", {search: this.search});
                let response2 = await this.$store.dispatch("viewAccessCustomer", {});
                let getcheckaccess = response2.data.record.find(item => item.Path == "/api/checktoken");
                let getcheckaccess2 = getcheckaccess.TypeRole.slice(1, -1).split(', ');
                let checkaccesstoobj = [];
                for (let i=0; i<getcheckaccess2.length; i++){
                    let type = Number(getcheckaccess2[i].split('#')[0]);
                    let role = Number(getcheckaccess2[i].split('#')[1]);
                    checkaccesstoobj.push({type: type, role: role});
                }
                console.log("getcheckaccess", checkaccesstoobj);

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                this.profilerole = dt;
                let dt2 = this.profiletype;
                let newarr = [];
                let recordCount = response.data.count;

                for(var i=0; i<dt.length; i++){
                    for(var j=0; j<dt2.length; j++){
                        let item = {
                            RoleID: dt[i].ID,
                            RoleName: dt[i].RoleName,
                            TypeID: dt2[j].ID,
                            TypeName: dt2[j].TypeName,
                            TypeRole: `${dt2[j].TypeName}: ${dt[i].RoleName}`,
                            displayStatus: 'N/A',
                            createdDate: 'N/A',
                            updatedDate: 'N/A'
                        };
                        newarr.push(item);
                    }
                    //replace(/\s/g,'') remove any literal whitespace
                }

                let selectedFinal = newarr.filter(a => checkaccesstoobj.some(b => this.comparator(a, b)));

                this.items = selectedFinal;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        comparator(a, b) {
            return (a.TypeID == b.type) && (a.RoleID == b.role);
        },

        async getProfileType(){
            try{
                let response = await this.$store.dispatch("listProfileTypeCustomer", {search: this.search});

                this.profiletype = response.data.record;
            }

            catch(err){
                console.log(err);
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addAccessPermission(v){
            let typeselected = v.type.selected;
            let roleselected = v.role.selected;
            console.log('v', typeselected + '--' + roleselected);
            let addexisting = this.items.map(item => `${item.TypeID}#${item.RoleID}`);
            addexisting.push(`${typeselected}#${roleselected}`);
            let arrtostring = addexisting.join();
            arrtostring = `[${arrtostring}]`;
            console.log('existing', arrtostring);

            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = [
                    {path: '/api/checktoken', typerolearray: arrtostring},
                    {path: '/api/master/adminprofile/role/view/all', typerolearray: arrtostring},
                    {path: '/api/master/adminprofile/type/view/all', typerolearray: arrtostring},
                    {path: '/api/master/customerprofile/role/view/all', typerolearray: arrtostring},
                    {path: '/api/master/customerprofile/type/view/all', typerolearray: arrtostring},
                    {path: '/api/profile/customer/view/all', typerolearray: arrtostring},
                    {path: '/api/config_carpark/view/all', typerolearray: arrtostring},
                    {path: '/api/config_system/view/all', typerolearray: arrtostring},
                    {path: '/api/config_operator/view/all', typerolearray: arrtostring},
                    {path: '/api/config_operator/view/search', typerolearray: arrtostring},
                    {path: '/api/config_carparkbay/view/all', typerolearray: arrtostring},
                    {path: '/api/view/customer/list/all', typerolearray: arrtostring},
                    {path: '/api/view/admin/list/all', typerolearray: arrtostring},
                    {path: '/api/config_location_country/view/all', typerolearray: arrtostring},
                    {path: '/api/config_location_country/view/search', typerolearray: arrtostring},
                    {path: '/api/config_location_state/view/search', typerolearray: arrtostring},
                    {path: '/api/config_location_city/view/search', typerolearray: arrtostring},
                    {path: '/api/config_location_postcode/view/search', typerolearray: arrtostring},
                    {path: '/api/gatecontroller/view/all', typerolearray: arrtostring},
                    {path: '/api/gate/view/all', typerolearray: arrtostring},
                    {path: '/api/profile/admin/new', typerolearray: arrtostring},
                    {path: '/api/admin/resetpassword/email', typerolearray: arrtostring},
                    {path: '/api/profile/admin/remove', typerolearray: arrtostring},
                    {path: '/api/profile/admin/update', typerolearray: arrtostring},
                    {path: '/api/valuepass/vpownershipbyvppassid/list/all', typerolearray: arrtostring},
                    {path: '/api/parking/ticket/view/transactions/payment/byparkingentryid', typerolearray: arrtostring},
                    {path: '/api/parkingtoken/view/transaction/addtoken/all', typerolearray: arrtostring},
                    {path: '/api/parkingtoken/view/transaction/deducttoken/all', typerolearray: arrtostring},
                    {path: '/api/accesspermission_customer/view/all', typerolearray: arrtostring},
                    {path: '/api/parking/transactions/snatch/report', typerolearray: arrtostring},
                    {path: '/api/valuepass/vppurchasebyvpproductid/report', typerolearray: arrtostring},
                    {path: '/api/customer/changemypassword', typerolearray: arrtostring},
                    {path: '/api/customer/corporateaccount/mycorporateaccount', typerolearray: arrtostring},
                    {path: '/api/customer/corporateaccount/updateaccount', typerolearray: arrtostring},
                    {path: '/api/view/customer/checkexist', typerolearray: arrtostring},
                    {path: '/api/prate_ratecard/view/all', typerolearray: arrtostring},
                    {path: '/api/prate_ratecard/view/bycarparkid', typerolearray: arrtostring},
                    {path: '/api/wheellockcontroller/view/all', typerolearray: arrtostring},
                    {path: '/api/wheellockcontroller/:WLControllerID/item', typerolearray: arrtostring},
                    {path: '/api/wheellockmainboard/view/all', typerolearray: arrtostring},
                    {path: '/api/parkingwheellock/report/mobile', typerolearray: arrtostring},
                    {path: '/api/master/manualexit/category/listall', typerolearray: arrtostring},
                    {path: '/api/master/manualexit/issue/listall', typerolearray: arrtostring}
                ];
                let response = await this.$store.dispatch("updateAccessCustomer", {selected: req});
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.getProfileRole();
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Access Permission Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This access permission is already created`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        viewMore(row, flag){
            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        goToPermission(row){
            //this.ma.modalInfo = row;
            console.log(row.RoleID);
            this.$router.replace({ name: "Permissions", params: {role_id: row.RoleID, type_id: row.TypeID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addAccessPermission(value);
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getProfileRole();
          }, 800);
        }
    }
}
</script>